let IP_ADDRESS;
let access_token;

let user = localStorage.getItem('user');

const modalAnimateTime = 300;
const invalidChars = ["-", "+", "e",];

const lostForm = $('#lost-form');
const loginForm = $('#login-form');
const loginModalElm = $('#loginModal');
const registerForm = $('#register-form');
const spinBtnLoaderElm = $('.spin-btn-loader');
const assistantModalElm = $('#AssistantModal');
const loginFormWrapper = $('#modal-login-form-wrapper');

if (typeof pageType === "undefined") pageType = "";

const LOGIN_FORM_OPTIONS = {
		"form_elem_id": "#admsnLoginForm",
		"name_elem_id": "#admsnLoginName",
		"mobile_elem_id": "#AdmissionLoginPhone",
		"otp_elem_id": "#login_otp",
		"recaptcha_id": ['REGISTRATION', 'ADMISSION_FEES', 'PAID_COUNSELLING'].includes(pageType)
				? "admission-recaptcha-login" : null,/*	Don't pass  # in recaptcha_id	*/

		"error_message_id": "#admissionLoginErrorMsg",
		"submit_btn_id": "#AdmissionLoginBtn",
		"resend_otp_message": "#admsnLoginResendOtp"
};

let URL_PARAMS = new URLSearchParams(window.location.search);

let recaptchaLogin = document.getElementById('recaptcha-login');
let recaptchaAssistLogin = document.getElementById('recaptcha-assist-login');
let recaptchaContactForm = document.getElementById('recaptcha-contact-form');

var captchaContact;
var grecaptchaWidgetId1;
var grecaptchaWidgetId2;
var grecaptchaAdmissionLogin;

const RECAPTCHA_COMMON_OPTION = {'sitekey': RECAPTCHA_SITE_KEY, 'theme': 'light'};

function onloadCallback() {

		if (!!!URL_PARAMS.get('recaptcha_bypass_code')) {

				if (LOGIN_FORM_OPTIONS.recaptcha_id) {
						grecaptchaAdmissionLogin = grecaptcha.render(LOGIN_FORM_OPTIONS.recaptcha_id, {
								...RECAPTCHA_COMMON_OPTION,
								callback: (token) => $(LOGIN_FORM_OPTIONS.form_elem_id).submit()
						});
				}

				if (recaptchaContactForm) {
						captchaContact = grecaptcha.render(recaptchaContactForm, {
								...RECAPTCHA_COMMON_OPTION,
								callback: (token) => cfw[0].submit()
						});
				}

				if (recaptchaLogin) {
						grecaptchaWidgetId1 = grecaptcha.render(recaptchaLogin, {
								...RECAPTCHA_COMMON_OPTION,
								callback: (token) => phoneLogin(false, token)
						});
				}

				if (recaptchaAssistLogin) {
						grecaptchaWidgetId2 = grecaptcha.render(recaptchaAssistLogin, {
								...RECAPTCHA_COMMON_OPTION,
								callback: (token) => AssistPhoneLogin(false, token)
						});
				}
		}
}

const hideLoginBtnSpin = () => spinBtnLoaderElm.hide();

const showLoginBtnSpin = () => spinBtnLoaderElm.show();

function validateLoginFormOnPage(event, formOptions, grecaptchaVariable) {
		event.preventDefault();
		if (checkLoginFormValidation(formOptions, 'submit')) {
				$(formOptions.error_message_id).html("");
				$(formOptions.resend_otp).html("");
				//when all case passed like mobile number and name then executing recaptcha
				showLoginBtnSpin();
				initLoginFormSubmit(formOptions, grecaptchaVariable);

				if ($(formOptions.form_elem_id).find(formOptions.otp_elem_id).length === 0) {
						grecaptcha.execute(grecaptchaVariable);
				} else if ($(formOptions.form_elem_id).find(formOptions.otp_elem_id).length > 0) {
						$(formOptions.form_elem_id).submit();
				}
		} else {
				$(formOptions.error_message_id).html("");
				$(formOptions.resend_otp).html("");
				if (!$(formOptions.error_message_id).html()) {
						if (!$(formOptions.name_elem_id).val()) {
								$(formOptions.error_message_id).append("Name is required.<br/>");
								return false;
						}
						//when phone number is less than 7
						if (($(formOptions.mobile_elem_id).val().length <= 7)) {
								$(formOptions.error_message_id).append("Phone number is required.<br/>");
								return false;
						}
						if ($(formOptions.form_elem_id).find(formOptions.otp_elem_id).length > 0 && !$(formOptions.otp_elem_id).val()) {
								$(formOptions.error_message_id).append("OTP is required.<br/>");
								return false;
						}

				}
		}
}

function initCountryCodeDropDown(input) {
		$(input).intlTelInput({
				separateDialCode: true,
				preferredCountries: ["in"],
				excludeCountries: ["am", "az", "ge", "de", "id", "kz", "kg", "ua", "uz", "tj"],
				autoPlaceholder: "Enter Mobile Number"
		});
}

function initLoginForm(options) {
		$(options.otp_elem_id).hide();
		initCountryCodeDropDown(options.mobile_elem_id);
}

function modalAnimate($oldForm, $newForm) {
		if (DEBUG) console.log('modal animate callled', $oldForm, $newForm);
		const $oldH = $oldForm.height();
		const $newH = $newForm.height();
		loginFormWrapper.css("height", $oldH);
		$oldForm.fadeToggle(modalAnimateTime, function () {
				loginFormWrapper.animate({height: $newH}, modalAnimateTime, () => $newForm.fadeToggle(modalAnimateTime));
		});
}


function resendLoginOtp(event) {

		event.preventDefault();

		if (validateFormLogin('resend')) {

				$('.login-form-error').html("");
				showLoginBtnSpin();

				if (typeof grecaptcha !== "undefined" && typeof grecaptchaWidgetId1 !== "undefined")
						grecaptcha.execute(grecaptchaWidgetId1);

				let loginFormResend = $('.login-form-resend');
				loginFormResend.empty();
				setTimeout(() => loginFormResend.html('OTP sent again successfully <br/>'), 1000);

		} else {

				let loginFormErrorElm = $('.login-form-error');
				if (!loginFormErrorElm.html()) {
						if (!$('#login_name').val()) loginFormErrorElm.append("Name is required.<br/>");
						if (!$('#login_phone').val()) loginFormErrorElm.append("Phone number is required.<br/>");
				}

		}
}

function validateFormLogin(type) {
		if (type === "submit") {

				if($('#login-form').find('#login-step-1').hasClass('show')) return !!($('#login_phone').val());

				if ($("#login-form").find("#login_otp").length > 0)
						return !!($('#login_phone').val() && $('#login_name').val() && $('#login_otp').val());
				else
						return !!($('#login_phone').val() && $('#login_name').val());
		} else if (type === "resend") return !!($('#login_phone').val() && $('#login_name').val());
		else return false;
}

function phoneLogin(background = false, recaptcha_token = null) {
		let loginStep1 = $('#login-step-1-tab');
		let sendOtpButton = $('#login-step-1').find('.btn-submit');
		let userName = $('#login_name');

	gtag('event', {
		event_category: 'Login/Signup',
		event_action: 'Phone Login Started',
		event_label: 'Phone Login Started'
	});

	gtag('event', {
		event_category: 'Total Signup',
		event_action: ' user_registration',
		event_label: ' user_registration'
	});

		// const ISD_CODE = $('#countryCodeMainLogin .current-isdcode').text().trim().replace('+', '');
		const ISD_CODE = $('.login-form-num .iti__selected-dial-code').text().trim().replace('+', '');

		let mobNo = $('#login_phone').val().replace(/ /g, '');
		if (mobNo.split('')[0] === "0") mobNo = mobNo.replace('0', '');
		if (ISD_CODE === '91' && mobNo.length > 10) {
				if (mobNo.slice(0, 2) === '91') mobNo = mobNo.replace('91', '');
		}

		let phone = ISD_CODE === '91' ? mobNo : ISD_CODE + "-" + mobNo;
		let rndId = Cookies.get("userRandomIdentifier");
		let params = {
				// name: $('#login_name').val(),
				identifier: phone, /*	removing space from phone	*/
				role: 'parent',
				email: $('#login_email').val(),
				g_recaptcha_response: recaptcha_token,
				randomId: rndId
		};

		if (URL_PARAMS.get('recaptcha_bypass_code')) params['recaptcha_bypass_code'] = URL_PARAMS.get('recaptcha_bypass_code');

		if(sendOtpButton.is(':visible')) sendOtpButton.prop('disabled', true).text('Sending OTP');
		$('#mobNumOtp').text(`+${ISD_CODE + "-" + mobNo}`);
		userName.prop('disabled', false);

		showLoginBtnSpin();
		$.post(`${API_BASE_URL}auth/mobile`, params)
				.always(function (res) {
						let response = res.responseJSON || res;
						if (DEBUG) console.log('login', response);
						let otpElm = $(`<input id="login_otp" name="login_otp" class="form-control" type="hidden">`);
						if (response.code === 'OK') {
								hideLoginBtnSpin();
								// hideForOtp();
								$('.auth-error').text('');
								localStorage.setItem('user_type', response.data.code);
								if (response.data.code === 'USER_REGISTERED') {
										localStorage.setItem('user_type', response.data.code);
										if (!$("#login-form").find("#login_otp").val()) {
												let username = response.data.username;
												loginStep1.next().trigger('click');
												if(!!username) userName.val(response.data.username).prop('disabled', true);
										}
												otpElm.insertAfter($('#login-form .main_login'));

								} else if (response.data.code === 'USER_NEW') {
										if (!background) {
												if (!$("#login_otp").val()) {
														let username = response.data.username;

														console.log(username, 'username');

														loginStep1.next().trigger('click');
														if(!!username) userName.val(response.data.username).prop('disabled', true);
												}
														otpElm.insertAfter($('#login-form .main_login'));
										}
								} else if (DEBUG) console.log('invalid user registration status');
						} else if (response.code === 'E_BAD_REQUEST') {
								$('.auth-error').text('Please enter valid input').show();
								hideLoginBtnSpin();
						} else {
								$('.auth-error').text(response.data || 'An unknown error occurred.').show();
								hideLoginBtnSpin();
						}

						if (typeof grecaptcha !== "undefined" && typeof grecaptchaWidgetId1 !== "undefined")
								grecaptcha.reset(grecaptchaWidgetId1);
				});
}

function login(username, password) {
		const params = {identifier: username, password: password, role: 'parent'};
		showLoginBtnSpin();
		$.post(`${API_BASE_URL}auth/signin`, params)
				.always(function (res) {
						let response = res.responseJSON || res;
						if (DEBUG) console.log('login', response);
						if (response.code === 'OK') {
								const token = response.data.jwt;
								login_success(token);
								//useProofLogin(username);
								// GOOGLE ANALYTICS CODE FOR SUCCESSFULLY LOGINED USER
							gtag('event', {
								event_category: 'Login/Signup',
								event_action: 'Login Success',
								event_label: 'login Successfully'
							});
								hideLoginBtnSpin();
								getPrefLoc();

								if (window.location.pathname === '/admission/admission-modal.php') location.reload();

						} else if (response.data.code === 'E_USER_NOT_FOUND') {
								$('.error-text').text('Invalid username or Password. Try again!').show();
								hideLoginBtnSpin();
						} else {
								$('.error-text').text('An unknown error occurred.').show();
								hideLoginBtnSpin();
						}

				});

}

function login_success(token) {
		//logout(); // clear any previous session or storage

		const payload = jwt_decode(token);
		if (DEBUG) console.log('login', payload);

		localStorage.setItem('user', JSON.stringify(payload.user));
		localStorage.setItem('access_token', token);
		Cookies.set('access_token_parent', token, {domain: DOMAIN_NAME, expires: 30});

		if (window.location.pathname === '/parents/offers') {
				location.reload();
		}

		auth_init();
		if (user.role === 'school' || user.role === 'admin') {
				// router.navigate(['/school']);
		} else if (user.role === 'parent') {
				auth_ui_update();
				if (!pageType) updateEventUserId();
				if (pageType && pageType !== 'DETAILS-PAGE') updateEventUserId();
				if (pageType && pageType === 'SEARCH') {
						removeBlurLoginSearchPage();
						searchPageLoginAnalytics();
				}
				insertUserAnalytics('login');
				insertUserAnalytics('view', true);
				$('#CheckShortlistFixedButton').show();
				openWishlistSidePopup();
				if (pageType && pageType === 'DETAILS-PAGE') {
						blurPreviewDesign();
						sendVadNotificationProcessing();
						checkAdmissionAppliedForSchool();
				}
		} else {
				$('.auth-error').text('Invalid user type. Please contact our tech. Support').show();
				logout();
		}

}

function logout() {

		/**	clear token remove user from local storage to log user out  **/

		//reset new login form
		resetNewLoginForm();

		document.getElementById("login-form").reset();

		if (document.getElementById("otp-container"))
				document.getElementById("otp-container").remove();

		if (document.getElementById("resend-otp-container"))
				document.getElementById("resend-otp-container").remove();

		access_token = null;
		requestOptions = null;
		user = null;
		localStorage.removeItem('user');
		localStorage.removeItem('access_token');
		Cookies.remove('access_token_parent', {domain: DOMAIN_NAME});
		//Google Analytics for Logout User
	gtag('event', {
		event_category: 'Login/Signup',
		event_action: 'Logout',
		event_label: 'logout Successfully'
	});
		setLoginData();
		auth_ui_update();
		if (pageType && pageType === 'REGISTRATION') location.reload();

		//todo reset jwt on server side
}

function resetNewLoginForm(){
		$('#login-step-1-tab').trigger('click');
		$('#login-form')[0].reset();
		$('#login-form').find('#login_otp').remove();
		$('#login_otp').val('');
		$('#login-step-1').find("button[type='submit']").text('Send OTP').prop('disabled', false);
}

function reset_login_display() {
		loginForm.show();
		$('.error-text').css("display", "none");
		registerForm.hide();
		lostForm.hide();
		return false;
}

function auth_ui_update() {

		$('#profile-page').find('a').attr('href', `${DASHBOARD_HOST}parent/info`);
		$('#login_register_btn').attr('href', `${DASHBOARD_HOST}parent/auth/register`);
		loginModalElm.modal('hide');

		if (user) {
				$('.loginUserProfileBlock').show();
				$('.loginBtnBlock').hide();
				if (mobileCheck()) $('#shortlistHeaderHeartIcon').show();
				$('#btn-profile').show();
				$('#OfferMob').show();
				$('#shortlist').show();
				$('#btn-auth').hide();
				if (typeof resetChatLoginUI !== 'undefined') resetChatLoginUI();
				if (typeof beginCredyFlow !== 'undefined') beginCredyFlow();
				$('#CheckShortlistFixedButton').show();
		} else {
				$('#otp-off').removeClass('hide-for-otp'); // to show first screen
				$('#mob-num').removeClass('hide-for-otp'); // to show first screen
				$('#countryCodeMainLogin').removeClass('hide-for-otp'); // to show first screen
				$('#otp-on').addClass('hide-for-otp'); //second step

				$('.loginUserProfileBlock').hide();
				$('.loginBtnBlock').show();
				$('#shortlistHeaderHeartIcon').hide();
				$('#btn-profile').hide();
				$('#shortlist').hide();
				$('#OfferMob').hide();
				$('#wishlist-container').hide();
				$('#btn-auth').show();
				$('#CheckShortlistFixedButton').hide();
		}
}

function auth_init() {
		if (!localStorage.getItem('user')) localStorage.removeItem('user');
		access_token = Cookies.get('access_token_parent') || '';
		localStorage.setItem('access_token', access_token);
		if (!localStorage.getItem('user') && access_token) {
				const payload = jwt_decode(access_token);
				localStorage.setItem('user', JSON.stringify(payload.user));
		}
		user = JSON.parse(localStorage.getItem('user'));

		if (!access_token) {
				localStorage.removeItem('user');
				user = null;
		}

		// set ajax requests header
		$.ajaxSetup({headers: {Authorization: `Bearer ${access_token}`}});

		// add authorization header with jwt token
		//let headers = new Headers({ 'Authorization': 'Bearer ' + access_token });
		//requestOptions = new RequestOptions({ headers: headers });
		setRandomUserId();
		if (!pageType) updateEventUserId();
		if (pageType && pageType !== 'DETAILS-PAGE') updateEventUserId();
		auth_ui_update();

		if (access_token) checkTokenExpired(function (status) {
				if (DEBUG) console.log('checkExpiredToken response', status);
				if (status && typeof getPrefLoc === "function") getPrefLoc();
				if (status) {
						if (DEBUG) console.log('user logged in Successfully');
						goog_report_conversion();
				} else logout();
		});
}

function checkTokenExpired(callback) {
		$.ajax({
				url: `${API_BASE_URL}parents/ping`,
				method: 'GET',
				success: function (result) {
						if (callback) (result.code === 'OK') ? callback(true) : callback(false);

						/*------------------------------- styles & scripts ----------------------------*/
						lazyLoadCss([
								`${HOST}//assets/build/shortlist.css`,
						], 'last', 100);

						lazyLoadThisScript('body', [
								{"src": `${HOST}/assets/build/shortlist.js`},
						], 'last', 100);
						/*------------------------------- styles & scripts ----------------------------*/


					if (typeof schoolAdmissionNoticeModal === 'function') {
						schoolAdmissionNoticeModal();
					}

				},
				error: function (response, msg, error) {
						if (DEBUG) console.log('auth token err', response, msg, error);
						//if(error === 'Unauthorized')
						if ((['E_UNAUTHORIZED', 'E_USER_NOT_FOUND'].includes(response.responseJSON.code)) && callback) callback(false);
				}
		});
}

function register() {
		let self = this;
		const ISD_CODE = $('.current-isdcode').text().trim().replace('+', '');
		let mobNo = $('#login_phone').val().replace(/ /g, '');
		if (mobNo.split('')[0] === "0") mobNo = mobNo.replace('0', '');

		if (ISD_CODE === '91' && mobNo.length > 10) {
				if (mobNo.slice(0, 2) === '91') mobNo = mobNo.replace('91', '');
		}
		let phone = ISD_CODE === '91' ? mobNo : `${ISD_CODE}-${mobNo}`;

		let name = $('#register_username').val();
		let password = $('#register_password').val();
		if ($('#register_password_confirm').val() !== password) {
				$('.error-text').text('Password doesn\'t, match try again').show();
				return;
		}
		let otp = $('#register_otp').val();
		let rndId = Cookies.get("userRandomIdentifier");

		const params = {
				identifier: phone,
				role: 'parent',
				verification_key: otp,
				name: name,
				password: password,
				randomId: rndId
		};
		showLoginBtnSpin();

		$.post(`${API_BASE_URL}auth/verify`, params).always(function (res) {
				let response = res.responseJSON || res;
				if (typeof (schoolType) === 'undefined') schoolType = '';
				if (DEBUG) console.log('login', response);
				if (response.code === 'OK') {
						if (DEBUG) console.log('verified', response);
						login_success(response.data.jwt);
					gtag('event', {
						event_category: 'Login/Signup',
						event_action: 'Register Success',
						event_label: 'Register Successfully',
						value: schoolType
					});

						schoolTypeRegisterAnalytics(schoolType);

						hideLoginBtnSpin();
						getPrefLoc();
						if (window.location.pathname === '/admission/admission-modal.php') location.reload();

						// Register Google Analytics Code

				} else if (response.code === 'E_UNAUTHORIZED') {
						$('.error-text').text('Incorrect OTP').show();
						hideLoginBtnSpin();
				} else {
						$('.error-text').text('An unknown error occurred.').show();
						hideLoginBtnSpin();
				}
		});
}

function getSearchParams(k) {
		let p = {};
		location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (s, k, v) => p[k] = v);
		return k ? p[k] : p;
}

function updateEventUserId() {
		if (!Cookies.get('userRandomIdentifier') || !user) return;
		let param_setLeadId = '';
		if (typeof setLeadTypeId != 'undefined') param_setLeadId = setLeadTypeId;

		let param_data = {
				random_userId: Cookies.get('userRandomIdentifier'),
				setLeadTypeId: param_setLeadId,
				client_ip: getSearchParams('client_ip')
		};

		$.ajax({
				type: "POST",
				url: `${HOST}/events/update_event_userId.php`,
				cache: false,
				data: param_data,
				headers: {
						'Cache-Control': 'no-cache, no-store, must-revalidate',
						'Pragma': 'no-cache',
						'Expires': '0'
				},
				success: () => {
				}
		});
}

function hideForOtp() {
		const add = document.getElementById("otp-off");
		const add1 = document.getElementById("mob-num");
		const add2 = document.getElementById("countryCodeMainLogin");
		const add3 = document.getElementById("email-id");
		const add4 = document.getElementById("login-img");
		const rem = document.getElementById("otp-on");
		const rem2 = document.getElementById("otp-img");
		const rem5 = document.getElementById("ourFeaturesInfo");

		add.classList.add("hide-for-otp")
		add1.classList.add("hide-for-otp");
		add2.classList.add("hide-for-otp");
		add3.classList.add("hide-for-otp");
		add4.classList.add("hide-for-otp");
		rem5.classList.add("hide-for-otp");
		rem.classList.remove("hide-for-otp");
		rem2.classList.remove("hide-for-otp");
}

function showMob() {
		const mob = document.getElementById("mob-num");
		const mobi = document.getElementById("countryCodeMainLogin");
		mob.classList.remove("hide-for-otp");
		mobi.classList.remove("hide-for-otp");
}

function schoolTypeRegisterAnalytics(schoolType) {
		// debugger;
		let schTypeArr = [
				{id: 1, text: "Day School"},
				{id: 2, text: "Play School"},
				{id: 3, text: "Boarding School"},
				{id: 4, text: "Boarding School"}
		];

		let {text = ""} = schTypeArr.find(({id = 0}) => id === parseInt(schoolType)) || {};

	gtag('event', {
		event_category: 'Login/Signup',
		event_action: `${text} Register Success`,
		event_label: `${text} Register Successfully`
	});
}

function verifyUser() {
		let self = this;
		// const ISD_CODE = $('#countryCodeMainLogin .current-isdcode').text().trim().replace('+', '');
		const ISD_CODE = $('.login-form-num .iti__selected-dial-code').text().trim().replace('+', '');
		let mobNo = $('#login_phone').val().replace(/ /g, '');
		if (mobNo.split('')[0] === "0") mobNo = mobNo.replace('0', '');
		if (ISD_CODE === '91' && mobNo.length > 10) if (mobNo.slice(0, 2) === '91') mobNo = mobNo.replace('91', '');
		let phone = ISD_CODE === '91' ? mobNo : `${ISD_CODE}-${mobNo}`;
		let name = $('#login_name').val();
		let otp = $('#login_otp').val();
		let rndId = Cookies.get("userRandomIdentifier");

		const params = {
				identifier: phone,
				role: 'parent',
				verification_key: otp,
				name: name,
				email: $('#login_email').val(),
				randomId: rndId
		};
		showLoginBtnSpin();

		$.post(`${API_BASE_URL}auth/verify`, params).always(function (res) {

				let response = res.responseJSON || res;
				if (typeof (schoolType) === 'undefined') schoolType = '';
				if (DEBUG) console.log('login', response);
				if (response.code === 'OK') {

						if (DEBUG) console.log('verified', response);

						login_success(response.data.jwt);

					gtag('event', {
						event_category: 'Total Signup',
						event_action: 'user_verified',
						event_label: 'user_verified'
					});

					gtag('event', {
						event_category: 'Login/Signup',
						event_action: 'Register Success',
						event_label: 'Register Successfully',
						value: schoolType
					});

						increaseVersionLoginCount();
						schoolTypeRegisterAnalytics(schoolType);
						hideLoginBtnSpin();
						getPrefLoc();
						if (window.location.pathname === '/admission/admission-modal.php') location.reload();

						/*	Register Google Analytics Code	*/

				} else if (response.code === 'E_UNAUTHORIZED') {
						$('.login-form-error').append('Incorrect OTP').show();
						hideLoginBtnSpin();
				} else {
						$('.login-form-error').append('An unknown error occurred.').show();
						hideLoginBtnSpin();
				}

		});
}

function insertUserAnalytics(user_type, login_success = false, clicked_link = '') {

		let ref_url = RefererURL;

		/*	page url with query parameter	*/
		let page_url = window.location.pathname + window.location.search;

		let school_id = (typeof (schoolId) !== "undefined") ? schoolId : null;

	/*For registration page */
	let school_id_reg = (typeof (SchoolID) !== "undefined") ? SchoolID : null;
	/*For registration page - END */

		if (user_type === 'login') {
				if (Cookies.get('home_page_new') !== undefined) clicked_link = 'v=' + Cookies.get('home_page_new');
		}
		let analyticsPageType = (typeof seoPageType !== 'undefined' && !!seoPageType) ? seoPageType : pageType;

		$.ajax({
				type: "POST",
				url: `${HOST}/analytics/user/user-analytics.php`,
				data: {
						login: login_success,
						user_type: user_type,
						rand_id: Cookies.get('userRandomIdentifier'),
						page_type: analyticsPageType,
						path: page_url,
						school_id: school_id ? school_id : school_id_reg ,
						data_text: clicked_link,
						refer_url: ref_url,
						ip_address: (typeof (IP_ADDRESS) !== "undefined") ? IP_ADDRESS : ''
				},
				success: function () {
				}
		})
}

function openWishlistSidePopup() {

		if (window.location.href.indexOf('shortlist') > -1) {
				if (pageType && pageType === 'HOMEPAGE') {
						if (user) {
								loadWishlist();
								$('#ShortlistModal').modal('show');
								wishlistModalOpenAnalytics();
						} else {
								if (tokenExpired) {
										$.toaster({
												priority: 'danger',
												title: 'Error',
												message: 'Your link has expired, Please login again or contact us',
												settings: {timeout: 4500}
										});
								}
								loginModalElm.modal('show', {backdrop: 'static', keyboard: false});
						}
				}
		}
}

function wishlistModalOpenAnalytics() {
		if (!user) return;
		$.ajax({
				type: "GET",
				url: `${HOST}/home/wishlist-new/server/wishlist-modal-open-analytics.php`,
				success: function (data) {
						if (DEBUG) console.log(data);
				}
		})
}

function increaseVersionLoginCount() {
		let homepageVersion = Cookies.get('home_page_new');
		if (user && (homepageVersion === '3' || homepageVersion === '4')) {
				$.ajax({
						type: "POST",
						url: `${HOST}/test/ui-split/increase-login-count.php`,
						data: {version: homepageVersion},
						success: function (data) {
								if (DEBUG) console.log(data);
						}
				});
		}
}

function searchPageLoginAnalytics() {
	gtag('event', {
		event_category: 'Login/Signup',
		event_action: 'Search Login Success',
		event_label: 'Search Login Success'
	});
}

function setLoginData() {
		if (localStorage.getItem('user_login_data')) {
				let user_login_data = JSON.parse(localStorage.getItem('user_login_data'));
				let user_phn = user_login_data.phone;
				$('#login_name').val(user_login_data.name);
				$('#login_phone').val(user_phn.slice(user_phn.lastIndexOf('-') + 1, user_phn.length));
				$(`#country-code li[data-isdcode="+${user_phn.slice(0, user_phn.indexOf('-'))}"] a`).click();
		}
}

function setRandomUserId() {
		if (!user && (!localStorage.getItem('userRandomIdentifier') || !Cookies.get('access_token_parent'))) {
				let rendomUserId = `R_${Math.random().toString(36).slice(2)}.${Math.floor(Math.random() * Date.now())}`;
				let userid = localStorage.getItem('userRandomIdentifier') || rendomUserId;
				if (Cookies.get('access_token_parent')) userid = true;

				if(userid.indexOf('R') === -1) userid = rendomUserId;

				localStorage.setItem('userRandomIdentifier', userid);
				Cookies.set('userRandomIdentifier', userid, {domain: DOMAIN_NAME, expires: 15});
		}
}

function useProofLogin(name) {

		let email = 'subu' + Math.floor(Math.random() * 1000000000) + '@gmail.com';
		$.ajax({
				type: 'POST',
				url: `${HOST}/useproof-webhook.php`,
				data: {first_name: name, email: email},
				success: function (data) {
						if (DEBUG) console.log('USEPROOF', data);
				}
		});

}

function numAssignOtp() {
		$('#mobNumOtp').text($('#login_phone').val());
}

let digitValidate = function(ele){
		ele.value = ele.value.replace(/[^0-9]/g,'');
}

const inputNumberLength = (e) => {
		const { value, maxLength } = e.target;
		if (value > maxLength) {
				e.target.value = e.target.value.slice(0, maxLength);
		}
};

let tabChange = function (val) {
		let ele = document.querySelectorAll('.otp-input-wrapper input');

		if (ele[val - 1].value != '' && val < 4) {
				ele[val].focus()
		} else if (ele[val - 1].value == '' && val > 1) {
				ele[val - 2].focus()
		}

		/* update OTP input */
		let otpInputs = $('.otp-input-wrapper input');
		let otp1 = otpInputs.eq(0).val();
		let otp2 = otpInputs.eq(1).val();
		let otp3 = otpInputs.eq(2).val();
		let otp4 = otpInputs.eq(3).val();

		let otpStr = '';
		if(!!otp1) otpStr+=otp1;
		if(!!otp2) otpStr+=otp2;
		if(!!otp3) otpStr+=otp3;
		if(!!otp4) otpStr+=otp4;

		$('#login_otp').val(otpStr);

}

function indianPriceFormat(price) {
		price = price.toString();
		let lastThree = price.substring(price.length - 3);
		let otherNumbers = price.substring(0, price.length - 3);
		if (otherNumbers !== '') lastThree = ',' + lastThree;
		return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
}

$(document).ready(async function () {

		$('#loginModal').on('show.bs.modal', () => {
				let loginUserMobile = $('.login-user-mobile');

				if(loginUserMobile.parent('.iti').length === 0){
						loginUserMobile.intlTelInput({
								separateDialCode: true,
								preferredCountries: ["in"],
								excludeCountries: ["am", "az", "ge", "de", "id", "kz", "kg", "ua", "uz", "tj"],
								autoPlaceholder: "Enter Mobile Number"
						});
				}

		});

		// document.getElementById("login-btn").addEventListener("click", numAssignOtp);

		if (location.href.includes('chennai')) $('#login_email').show();
		else $('#login_email').hide();

		loginForm.off().on("submit", function (event) {
				event.preventDefault();
				if (validateFormLogin('submit')) {

						$('.login-form-error').empty();
						$('.login-form-resend').empty();
						$('.country-code-note').addClass('d-none').removeClass('d-block');
						showLoginBtnSpin();

						if($('#login-step-1').hasClass('show')) {
								grecaptcha.execute(grecaptchaWidgetId1);
						}
						else if ($('#login_otp').val().length === 4){
						 verifyUser();
						}

				} else {

						let loginFormErrorElm = $('.login-form-error');
						$('.login-form-resend').empty();
						if (!loginFormErrorElm.html()) {
								if (!$('#login_name').val()) loginFormErrorElm.append("Name is required.<br/>");
								if (!$('#login_phone').val()) loginFormErrorElm.append("Phone number is required.<br/>");

								if (!$('#login_otp').val().length) {
										loginFormErrorElm.append("OTP is required.<br/>");
								}
						}
				}
		});

		/*setTimeout(function(){
				$.get(`${HOST}/assets/selected_country_dial_info.json`, function (result) {

						let country_code_el = $('#country-code'); // for assist and login modal
						let requestCountryCode = $('select[id="requestCountryCode"]'); // for free counselling modal form
						let country_code_el_assist = $('#country-code-assist');
						let countryCodeStr = '';
						let countryCodeOptionStr = '';

						if (result) {
								result.forEach((item) => {
										countryCodeStr+=`<li class="isd-items notranslate" data-isdcode=${item.dial_code}>
															<a href="javascript:void()">${item.dial_code} - ${item.name}</a>
														</li>`;

										countryCodeOptionStr+=`<option data-country="${item.name}" value="${item.dial_code}"
																						${item.dial_code_num === 91 ? 'selected' : ''}>
																						${item.dial_code} (${item.name.slice(0, 20)}${item.name.length > 20 ? '...' : ''})
																					 </option>`;
								});

								country_code_el.append(countryCodeStr);
								// country_code_el_assist.append(countryCodeStr);
								requestCountryCode.append(countryCodeOptionStr);

								$('#country-code li.isd-items').off().on('click', (event) => {
										const code = $(event.target).parent('li').attr('data-isdcode');
										$('#countryCodeMainLogin .current-isdcode').attr('data-current-isdcode', code);
										$('#countryCodeMainLogin .current-isdcode').text(code);
								});

								/!*$('#country-code-assist li.isd-items').off().on('click', (event) => {
										const code_assist = $(event.target).parent('li').attr('data-isdcode');
										const isdcodeElm = $('#assist_countryCode .current-isdcode');
										isdcodeElm.attr("data-current-isdcode", code_assist);
										isdcodeElm.text(code_assist);
								});*!/
						}

				});
		}, 2000);*/

		/*	API RESPONSE	*/
		$.getJSON("https://jsonip.com?callback=?").done(({ip}) => {
				IP_ADDRESS = ip;
				insertUserAnalytics('view');
		}).fail(error => DEBUG ? console.log({error}) : 1);

		lostForm.off().on('submit', function (e) {
				e.preventDefault();
				const ISD_CODE = $('#countryCodeMainLogin .current-isdcode').text().trim().replace('+', '');
				let mobNo = $('#login_phone').val().replace(/ /g, '');
				if (mobNo.split('')[0] === "0") mobNo = mobNo.replace('0', '');
				if (ISD_CODE === '91' && mobNo.length > 10) {
						if (mobNo.slice(0, 2) === '91') {
								mobNo = mobNo.replace('91', '');
						}
				}
				let phone = ISD_CODE === '91' ? mobNo : `${ISD_CODE}-${mobNo}`;
				login(phone, $('#user_login_password').val());
		});

		registerForm.off().on('submit', function (e) {
				e.preventDefault();
				register();
		});

		$('#btn-auth').off().on("click", function () {
				if (DEBUG) console.log('login clicked');
				reset_login_display();
				$('.btn-google').show();

				/*	Header Login Button Clicked Google Analytics	*/
			gtag('event', {
				event_category: 'Login/Signup',
				event_action: 'Header Login',
				event_label: 'Header Login Clicked'
			});
		});

		$('#change_mobile').off().on("click", function () {
				modalAnimate(lostForm, loginForm)
		});

		$('#shortlist').on('click', function () {
			gtag('event', {
				event_category: 'Wishlist',
				event_action: 'shortlist button clicked',
				event_label: 'shortlist button clicked'
			});
		});

		$('#AdmissionLoginBtn').on('click', function (event) {

				if (pageType === 'REGISTRATION' && !user) {
						validateLoginFormOnPage(event, LOGIN_FORM_OPTIONS, grecaptchaAdmissionLogin);
						return false;
				}

				let checkParams = (typeof formAllFieldsCheck !== "undefined") ? formAllFieldsCheck() : false;
				if (checkParams && user) paidCounsellingFormSubmit();
				if (checkParams && !user) validateLoginFormOnPage(event, LOGIN_FORM_OPTIONS, grecaptchaAdmissionLogin);
		});

		$('#header a').off().on('click', function () {
				let header_link = $(this).attr('href');
				if (text !== '×') {
						if (typeof (header_link) !== "undefined") header_link = header_link.replace(HOST, "");

						if (header_link === '#AssistantModal')
								insertUserAnalytics('assistant', false, 'assistant open');
						else insertUserAnalytics('header', false, header_link);
				}
		});

		$('.footer a').off().on('click', function () {
				let footer_link = $(this).attr('href');
				let footerlink = footer_link.replace(HOST, "");
				insertUserAnalytics('footer', false, footerlink);
		});

		/*	GOOGLE ANALYTICS CODE FOR FB AND GOOGLE
			Facebook Login Button Google Analytics Start	*/
		$('a.btn.btn-facebook.btn-block').on('click', function (e) {
				e.preventDefault();
			gtag('event', {
				event_category: 'Login/Signup',
				event_action: 'Facebook',
				event_label: 'Facebook Button Clicked'
			});
				setTimeout(() => window.location = `${API_BASE_URL}auth/facebook`, 1000);
		});

		/*	Google Login Button Google Analytics Start	*/
		$('a.btn.btn-google.btn-block').on('click', function (e) {
				e.preventDefault();
			gtag('event', {
				event_category: 'Login/Signup',
				event_action: 'Google',
				event_label: 'Google Button Clicked'
			});
				setTimeout(() => window.location = `${API_BASE_URL}auth/google`, 1000);
		});

		/*	forget password Button Clicked Google Analytics	*/
		$('#forgot_password').on('click', function (e) {
				if (DEBUG) console.log('forgot pass clicked');
				e.preventDefault();
			gtag('event', {
				event_category: 'Login/Signup',
				event_action: 'Reset Password',
				event_label: 'Reset Password Clicked'
			});

				setTimeout(() => window.location = `${DASHBOARD_HOST}parent/auth/reset`, 1000)
		});

		/*	stop preventing tying - or + in phone number input	*/
		const loginPhoneElm = document.getElementById("login_phone");
		if (loginPhoneElm) {
				loginPhoneElm.addEventListener("keydown", function (e) {
						if (invalidChars.includes(e.key)) e.preventDefault();
				});
		}

		auth_init();
		Cookies.set('return_url', window.location.href, {domain: DOMAIN_NAME});
		initLoginForm(LOGIN_FORM_OPTIONS);
		console.log('loaded');

	$('form#login-form button[type="submit"]:not([id])').prop('disabled', true);

	$('input#login_phone').on('keyup', function (event) {
		const countryCode = $(event.target).siblings('div').find('div.iti__selected-dial-code').text();
		const phoneNumber = $(event.target).val();

		const phoneInput = countryCode + phoneNumber;

		const phoneInst = libphonenumber.parse(phoneInput);
		const phonePossible = libphonenumber.isPossibleNumber(phoneInput);
		const phoneValid = libphonenumber.isValidNumber(phoneInput);

		$('form#login-form button[type="submit"]:not([id])').prop('disabled', !(phonePossible && phoneValid));
	});

});

const verifyPhoneNumberFromGoogleScript = (phone, countryCode) => {
		let libphonenumber_API = new libphonenumber.PhoneNumberUtil();
		const phn = libphonenumber_API.parse(phone, countryCode);
		const possible = libphonenumber_API.isPossibleNumber(phn);
		const valid = libphonenumber_API.isValidNumber(phn);
		let response = false;
		if(!!valid && !!possible) response = true;

		if(DEBUG) console.log(possible, valid, countryCode);

		return  response;
}
